import React, { useRef } from "react";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import classNames from "classnames";
import Icon from "../icons/icon/Icon";

export interface iCheckbox {
  ariaLabel?: string;
  id: string;
  name: string;
  onChange?: (checked: boolean) => void;
  required?: boolean;
}

const Checkbox = ({
  ariaLabel,
  id = "",
  name = "",
  onChange,
  required,
}: iCheckbox) => {
  const myRef = useRef(null);

  const onCheckboxChange = (checked: boolean) => {
    if (typeof onChange !== "undefined") onChange(checked);

    const consentEvent = new CustomEvent("hnry:consent_changed", {
      bubbles: true,
      detail: { name, consent_given: checked },
    });

    myRef.current.dispatchEvent(consentEvent);

    const virtualChangeEvent = new CustomEvent("change", {
      bubbles: true,
      detail: { checked },
    });

    myRef.current.dispatchEvent(virtualChangeEvent);
  };

  return (
    <RadixCheckbox.Root
      className={classNames(
        "tw-flex tw-justify-center tw-items-center tw-w-5 tw-h-5 tw-aspect-square",
        "tw-bg-white tw-rounded-md tw-border tw-border-gray-300 tw-outline-none tw-cursor-pointer",
        "data-[state='checked']:tw-bg-blue-600 data-[state='checked']:tw-border-blue-600",
        "hover:tw-border-gray-400 hover:data-[state='checked']:tw-border-gray-400 ",
        "focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-blue-100",
        "disabled:tw-bg-gray-100 disabled:tw-border-gray-300 disabled:tw-cursor-not-allowed disabled:data-[state='checked']:tw-bg-gray-100",
      )}
      id={id}
      name={name}
      onCheckedChange={onCheckboxChange}
      aria-label={ariaLabel}
      ref={myRef}
      required={required}
    >
      <RadixCheckbox.Indicator className="tw-flex tw-justify-center tw-items-start">
        <Icon type="CheckIcon" classes="tw-text-white" size="sm" />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
};

export default Checkbox;
