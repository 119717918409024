import React, { useState, useEffect } from "react";
import Modal from "../../_molecules/modal/Modal";
import I18n from "../../../utilities/translations";
import SCAContent from "./SCAContent";
import SCAIcon from "./SCAIcon";
import SCAFooter from "./SCAFooter";
import { SCA_DEFAULT, SCA_FAILED, SCA_SUCCESS, confirmStatusType } from "./types";
import "./styles.scss";

interface iSCAConfirm {
  deviceName: string;
  actionName: string;
}

export const getSCATitle = (status: confirmStatusType) => {
  switch (status) {
    case SCA_SUCCESS:
      return I18n.t("sca_confirm.success_title");
    case SCA_FAILED:
      return I18n.t("sca_confirm.failed_title");
    default:
      return I18n.t("sca_confirm.default_title");
  }
}

const SCAConfirm = ({ deviceName, actionName }: iSCAConfirm) => {
  // TODO: Fix me when SCA backend work is done
  const [show, setShow] = useState(true);
  const [confirmStatus, setConfirmStatus] = useState(SCA_DEFAULT);

  useEffect(() => {
    if (window.MbvCallback) {
      window.MbvCallback.fetchChallenge?.();
    }
  }, []);

  const handleResendClick = async () => {
    try {
      // TODO: Fix me request for SCA once backend work is done
      const response = { success: true }

      if (response.success) {
        setConfirmStatus(SCA_SUCCESS);
        setTimeout(() => { setShow(false); }, 3000);
      } else {
        setConfirmStatus(SCA_FAILED);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`sca: User unable to ${actionName}`, { error });
    } finally {
      setConfirmStatus(SCA_DEFAULT);
    }
  }

  return (
    <Modal
      open={show}
      setOpen={setShow}
      title={getSCATitle(confirmStatus as confirmStatusType)}
      includesFooter={false}
      extraHeaderContent={<SCAIcon confirmStatus={confirmStatus as confirmStatusType} />}
      id="sca-confirmation-modal"
      modalClasses="hui-sca"
    >
      <div className="tw-flex tw-justify-between tw-flex-col">
        <SCAContent deviceName={deviceName} />
        <SCAFooter
          handleResendClick={confirmStatus === SCA_DEFAULT ? handleResendClick : null}
          handleCancelClick={() => setShow(false)}
        />
      </div>
    </Modal>
  );
}

export default SCAConfirm;
