/* eslint-disable jsx-a11y/no-redundant-roles */

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react"
import Icon from "../_atoms/icons/icon/Icon"
import { step as stepState } from "./progress_bar_step"

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export default function OnboardingProgressBar({ progressSteps, vertical }: { progressSteps: stepState[], vertical?: boolean }) {
    const [steps, setSteps] = useState(progressSteps)

    useEffect(() => {
        setSteps(progressSteps)
    }, [steps, progressSteps])

    const horizontalParentClass = `tw-min-h-max ${ vertical ? "tw-hidden" : "tw-hidden md:tw-block"}`
    const verticalParentClass = `tw-min-h-full tw-mt-4 ${ vertical ? "tw-block" : "tw-block md:tw-hidden"}`

    return (
        <>
            <nav aria-label="Progress" className={horizontalParentClass}>
                <div className="tw-w-full tw-flex tw-min-w-full">
                    <div className="tw-flex tw-items-center tw-min-w-full">
                        <div className={classNames(steps.length <= 3 ? `tw-grid-cols-${steps.length}` : "tw-grid-flow-col tw-col-auto", "tw-grid tw-min-w-full ")}>
                            {steps ? steps.map((step, stepIdx) => (

                                <div key={step.name} className="tw-grid tw-grid-rows-2 tw-align-middle">
                                    {step.status === "complete" ? (
                                        <>
                                            <div className="tw-flex tw-flex-row">
                                                <div className="tw-flex tw-items-center">
                                                    <div
                                                        className="tw-relative tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full 
                                                tw-bg-brand-green-600"
                                                    >
                                                        <Icon type="CheckIcon" classes="tw-text-white tw-space-8 tw-items-center tw-justify-center" strokeWidth="3.5" />

                                                    </div>
                                                </div>
                                                {/* this is the line between steps */}
                                                {stepIdx !== progressSteps.length - 1 ? (<div aria-hidden="true" className="tw-inset-0 tw-flex tw-items-center tw-w-full">
                                                    <div className="tw-h-0.5 tw-w-full tw-px-4 tw-bg-brand-green tw-space-x-8" ></div>
                                                </div>) : null}
                                            </div>
                                            <div className="tw-flex-row tw-text-sm tw-mr-4">
                                                <div className="tw-font-bold tw-text-gray-800">{step.name}</div>
                                                <div>{step.description}</div>
                                            </div>
                                        </>
                                    ) : step.status === "current" ? (
                                        <>
                                            <div className="tw-flex tw-flex-row">
                                                <div className="tw-flex tw-items-center">
                                                    <div
                                                        aria-current="step"

                                                        className="tw-relative tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border-2
                                                 tw-border-brand-green tw-bg-white"
                                                    >
                                                        <span aria-hidden="true" className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-brand-green-600" />
                                                        <span className="tw-sr-only">{step.name}</span>

                                                    </div>
                                                </div>

                                                {/* this is the line between steps */}
                                                {stepIdx !== progressSteps.length - 1 ? (<div aria-hidden="true" className="tw-inset-0 tw-flex tw-items-center tw-w-full">
                                                    <div className="tw-h-0.5 tw-w-full tw-px-4 tw-bg-brand-green-300 tw-space-x-8" ></div>
                                                </div>) : null}
                                            </div>
                                            <div className="tw-flex-row tw-text-sm tw-mr-4">
                                                <div className="tw-font-bold tw-text-brand-green-800">{step.name}</div>
                                                <div>{step.description}</div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="tw-flex tw-flex-row">
                                                <div className="tw-flex tw-items-center">
                                                    <div
                                                        aria-current="step"
                                                        className="tw-relative tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full
                                                 tw-border-2 tw-border-brand-green-300 tw-bg-white">
                                                        <span aria-hidden="true"
                                                            className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-transparent group-hover:tw-bg-gray-300" />
                                                        <span className="tw-sr-only">{step.name}</span>

                                                    </div>
                                                </div>
                                                {/* this is the line between steps */}

                                                {stepIdx !== progressSteps.length - 1 ? (<div aria-hidden="true" className="tw-inset-0 tw-flex tw-items-center tw-w-full">
                                                    <div className="tw-h-0.5 tw-w-full tw-px-4 tw-bg-brand-green-300 tw-space-x-8" ></div>
                                                </div>) : null}


                                            </div>
                                            <div className="tw-flex-row tw-text-sm tw-mr-4">
                                                <div className="tw-font-bold tw-text-gray-800">{step.name}</div>
                                                <div>{step.description}</div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )) : null}
                        </div>
                    </div>
                </div>
            </nav>

            {/* Mobile View */}
            <nav aria-label="Progress" className={verticalParentClass}>
                <ol role="list" className="tw-overflow-hidden">
                    {steps.map((step, stepIdx) => (
                        <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? "" : "", "tw-relative tw-h-20 tw-pb-8")}>
                            {step.status === "complete" ? (
                                <>
                                    {stepIdx !== steps.length - 1 ? (
                                        <div aria-hidden="true" className="tw-absolute tw-left-4 tw-top-4 tw--ml-px tw-mt-0.5 tw-h-full tw-w-0.5 tw-bg-brand-green" />
                                    ) : null}
                                    <div className="tw-relative tw-flex tw-items-start">
                                        <span className="tw-flex tw-h-9 tw-items-center">
                                            <span className="tw-relative tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full
                                             tw-bg-brand-green">
                                                <Icon type="CheckIcon" classes="tw-text-white tw-space-8 tw-items-center tw-justify-center" strokeWidth="3" />
                                            </span>
                                        </span>
                                        <span className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-col tw-mb-4">
                                            <span className="tw-text-sm tw-font-bold tw-text-gray-800">{step.name}</span>
                                            <span className="tw-text-sm tw-text-gray-500">{step.description}</span>
                                        </span>
                                    </div>
                                </>
                            ) : step.status === "current" ? (
                                <>
                                    {stepIdx !== steps.length - 1 ? (
                                        <div aria-hidden="true" className="tw-absolute tw-left-4 tw-top-4 tw--ml-px tw-mt-0.5 tw-h-full tw-w-0.5 tw-bg-brand-green-300" />
                                    ) : null}
                                    <div aria-current="step" className="group tw-relative tw-flex tw-items-start">
                                        <span aria-hidden="true" className="tw-flex tw-h-9 tw-items-center">
                                            <span className="tw-relative tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border-2
                                             tw-border-brand-green-600 tw-bg-white">
                                                <span className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-brand-green-600" />
                                            </span>
                                        </span>
                                        <span className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-col">
                                            <span className="tw-text-sm tw-text-brand-green-800 tw-font-bold ">{step.name}</span>
                                            <span className="tw-text-sm tw-text-gray-500">{step.description}</span>
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {stepIdx !== steps.length - 1 ? (
                                        <div aria-hidden="true" className="tw-absolute tw-left-4 tw-top-4 tw--ml-px tw-mt-0.5 tw-h-full tw-w-0.5 tw-bg-brand-green-300" />
                                    ) : null}
                                    <div className="group tw-relative tw-flex tw-items-start">
                                        <span aria-hidden="true" className="tw-flex tw-h-9 tw-items-center">
                                            <span className="tw-relative tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border-2 
                                            tw-border-brand-green-300 tw-bg-white">
                                                <span className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-transparent " />
                                            </span>
                                        </span>
                                        <span className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-col">
                                            <span className="tw-text-sm tw-font-bold tw-text-gray-800">{step.name}</span>
                                            <span className="tw-text-sm tw-text-gray-500">{step.description}</span>
                                        </span>
                                    </div>
                                </>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
        </>

    )
}