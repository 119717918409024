import React from "react";
import Button from "../../_atoms/button/Button";
import I18n from "../../../utilities/translations";

interface iSCAFooter {
  handleResendClick?: () => void;
  handleCancelClick: () => void;
}

const SCAFooter = ({
  handleResendClick,
  handleCancelClick,
}: iSCAFooter) => (
  <div className="tw-flex tw-flex-col tw-gap-y-3 tw-m-1">
    {handleResendClick && (
      <Button
        onClick={handleResendClick}
        variant="secondary"
      >
        {I18n.t("sca_confirm.resend_button")}
      </Button>
    )}
    <Button onClick={handleCancelClick} variant="tertiary">
      {handleResendClick ? I18n.t("sca_confirm.cancel_button") : I18n.t("sca_confirm.close_button")}
    </Button>
  </div>
);

export default SCAFooter;
