import { attachCopyContentListeners } from "./copy";
import addRequiredIfChecked from "./addRequiredIfChecked";
import disableFormSubmitUntilValid from "./disableFormSubmitUntilValid";
import dismissNotification from "./dismissNotification";
import loggedOutAnalyticsHelper from "./loggedOutAnalyticsHelper";
import unsavedChangesAlert from "./unsavedChangesAlert";

/**
 * Calling a bunch of legacy functions that are expected to be called on page load
 * They mostly attach event listeners to the document for delegated events
 */
addRequiredIfChecked();
attachCopyContentListeners();
dismissNotification();
loggedOutAnalyticsHelper();
unsavedChangesAlert();

window.initializeFormDisabledButtons = disableFormSubmitUntilValid;
