import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import CardModuleFooter, { iCardModuleFooter } from "./CardModuleFooter";
import Card from "../../../assets/images/hnry_card/hnry_card_au_nz.svg";
import CardUk from "../../../assets/images/hnry_card/hnry_card_uk.svg";
import FrozenMask from "../../../assets/images/hnry_card/frozen_mask.svg";
import Alert from "../_molecules/alert/Alert";
// eslint-disable-next-line import/no-named-as-default
import formatToLocalCurrency from "../../utilities/currency/currency_format";
import { getUserJurisdictionCode } from "../../utilities/user_attributes";

interface iCardContent extends iCardModuleFooter {
  cardNumber?: string;
  canWithdraw: boolean;
  jurisdiction: string;
}

const CardContent = ({
  appleWalletLink,
  availableBalance,
  cardBlocked,
  cardNumber,
  googleWalletLink,
  intercomLink,
  otpRequiredForLogin,
  userEmail,
  userPhoneNumber,
  hnryBsb,
  hnryAccountNumber,
  canWithdraw,
  allocation,
  allocationTemplate,
  allocationFrequencyOptions,
  isImpersonating,
  maxAllocationPercentage,
  jurisdiction,
}: iCardContent) => {
  const [cardFrozen, setCardFrozen] = useState(cardBlocked);

  return (
    <>
      <div className="tw-text-left tw-text-black tw-self-start">
        { availableBalance ? (
            <>            
              <h3 className={classNames("tw-text-xs tw-m-0", { "tw-text-gray-500": cardFrozen })}>Your Balance</h3>
              <span className={classNames("tw-text-2xl", { "tw-text-gray-500": cardFrozen })}>
                {formatToLocalCurrency(Number(availableBalance), getUserJurisdictionCode(), {decimals: true})}
              </span>
            </>
          ) : (
            <Alert variant="info">
              Unable to get card balance. Please try again later.
            </Alert>
          ) }

      </div>
      <div className="tw-relative tw-mt-3 tw-mb-4 tw-mx-3 sm:tw-mx-6">
        <img
          src={getUserJurisdictionCode() === "uk" ? CardUk : Card}
          className="tw-w-full tw-rounded-xl"
          alt={"Hnry debit card"} />
        <div className="tw-absolute tw-bottom-0 tw-left-0 tw-p-4 tw-text-white">•• {cardNumber}</div>
        <Transition
          enter="tw-transition-opacity tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-transition-opacity tw-duration-300"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
          show={cardFrozen}>
          <div className="tw-w-full">
            <img src={FrozenMask} className="tw-absolute tw-bottom-0 tw-w-full tw-opacity-60 tw-rounded-xl" alt="Frozen mask" />
            <div className="tw-absolute tw-bottom-0 tw-bg-white tw-opacity-60 tw-h-full tw-w-full"></div>
          </div>
        </Transition>
      </div>
      <CardModuleFooter
        appleWalletLink={appleWalletLink}
        availableBalance={availableBalance}
        cardBlocked={cardFrozen}
        googleWalletLink={googleWalletLink}
        intercomLink={intercomLink}
        otpRequiredForLogin={otpRequiredForLogin}
        setCardFrozen={setCardFrozen}
        userEmail={userEmail}
        userPhoneNumber={userPhoneNumber}
        hnryBsb={hnryBsb}
        hnryAccountNumber={hnryAccountNumber}
        canWithdraw={canWithdraw}
        allocationFrequencyOptions={allocationFrequencyOptions}
        allocationTemplate={allocationTemplate}
        allocation={allocation}
        isImpersonating={isImpersonating}
        maxAllocationPercentage={maxAllocationPercentage}
        jurisdiction={jurisdiction}
      />
    </>
  );
};

export default CardContent;
