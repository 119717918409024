import React, { useState } from "react";
import QRCode from "react-qr-code";
import MoreActions from "@hui/_molecules/dropdowns/more_actions/MoreActions";
import MenuItem from "@hui/_molecules/dropdowns/menu/MenuItem";
import BlockingModal from "@hui/_molecules/blocking_modal/BlockingModal";
import { User } from "app/javascript/global";
import { PlatformType } from "@hui/_organisms/navigation_sidebar_panel/types";
import Button from "../_atoms/button/Button";
import I18n from "../../utilities/translations";

export interface iWaitlistModal {
  currentUser: User;
  id?: string;
  onOpenAutoFocus?: (event: Event) => void;
  isNative: boolean;
  appPlatformName: PlatformType;
  headerImagePath
}

interface iModalContentEl {
  appPlatformName: PlatformType;
}

const NativeAppContent = () => (
  <div className="tw-text-gray-700">
    <p>{I18n.t("home.uk_waitlist.paragraph_1")}</p>
    <p className="tw-mb-0 tw-font-bold">
      {I18n.t("home.uk_waitlist.paragraph_2.title")}
    </p>
    <p>{I18n.t("home.uk_waitlist.paragraph_2.native_app")}</p>
  </div>
)

const BrowserContent = ({ appPlatformName }: iModalContentEl) => {
  const [showQRCode, setShowQRCode] = useState(false);

  return (
    <div className="tw-text-gray-700">
      <p>{I18n.t("home.uk_waitlist.paragraph_1")}</p>
      <p className="tw-mb-0 tw-font-bold">
        {I18n.t("home.uk_waitlist.paragraph_2.title")}
      </p>
      <p>{I18n.t("home.uk_waitlist.paragraph_2.browser")}</p>

      {appPlatformName === "web_ios" &&
        (<a className="hnry-button hnry-button--primary !tw-block tw-w-full tw-mt-2" href="https://hnry.co.uk/app/ios">{I18n.t("home.uk_waitlist.buttons.get_app_ios")}</a>)
      }
      {appPlatformName === "web_android" &&
        (<a className="hnry-button hnry-button--primary !tw-block tw-w-full tw-mt-2" href="https://hnry.co.uk/app/android">{I18n.t("home.uk_waitlist.buttons.get_app_android")}</a>)
      }
      {appPlatformName !== "web_ios" && appPlatformName !== "web_android" &&
        <>
          {showQRCode &&
            <div className="tw-p-4 tw-border-gray-300 tw-border tw-rounded-lg">
              <div className="tw-flex tw-justify-center"><QRCode value={"https://hnry.co.uk/?m=app&q=true"} className="tw-h-36 tw-w-36 tw-mb-4" /></div>
              <p className="tw-text-center tw-font-semibold tw-mb-0">{I18n.t("home.uk_waitlist.qr_code")}</p>
            </div>
          }
          {!showQRCode &&
            <Button onClick={() => setShowQRCode(true)} variant="primary" classes="!tw-block tw-w-full tw-mt-2">{I18n.t("home.uk_waitlist.buttons.get_app_other")}</Button>
          }
        </>
      }
    </div>
  )
}

const IntercomNavigation = (
  isNative: boolean,
  currentUser: User,
) => {
  if (isNative || typeof Intercom === "undefined") {
    const href = isNative
      ? "/app/open_intercom"
      : `mailto:${currentUser.jurisdiction.supportEmail}`;

    return (
      <MenuItem label={I18n.t("home.uk_waitlist.more_actions.leave_waitlist")} href={href} />
    );
  }
  return (
    <MenuItem
      label={I18n.t("home.uk_waitlist.more_actions.leave_waitlist")}
      asButton={true}
      onClick={() => {
        Intercom("show");
      }}
    />
  );
};

const moreActions = (isNative, appPlatformName, currentUser) => (
  <div className="tw-mr-1">
    <MoreActions>
      {!isNative &&
        <MenuItem label={I18n.t("home.uk_waitlist.more_actions.sign_out")} href="users/sign_out" />
      }
      {IntercomNavigation(isNative, currentUser)}
    </MoreActions>
  </div>

)

const waitlistModal = ({
  isNative,
  appPlatformName,
  headerImagePath,
  currentUser
}: iWaitlistModal) => (
  <BlockingModal
    hideOverlay={true}
    title={I18n.t("home.uk_waitlist.title")}
    headerImagePath={headerImagePath}
    moreActions={moreActions(isNative, appPlatformName, currentUser)}
  >
    {isNative ? <NativeAppContent /> : <BrowserContent appPlatformName={appPlatformName} />}
  </BlockingModal>

);

export default waitlistModal;
